import React,{useEffect, useState} from 'react'
import Alert_Modals from '../../components/Alert_Modals';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from "moment";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import "../css/general.css"
import { get_member_account } from '../../functions/api_call_functions';

function Transaction_details_modal({modal_status,Modal_toggle,refresh_data,transaction_data}) {
    const toggle=()=>{
        Modal_toggle();
       
    }
    const nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    const [Member_data,setData]=useState({
        firstname:"",
        lastname:"",
        email:"",
        phonenumber:"",
    })
    useEffect(()=>{
        get_member_account(transaction_data?.account_id).then((res)=>{{
            console.log(res.user);
            setData({
                firstname:res.user?.firstname,
                lastname:res.user?.lastname,
                email:res.user?.email,
                phonenumber:res.user?.phonenumber,
                image:res.user?.profile_image,
            })
        }})
    },[transaction_data])
  return (
    <div className='container-fluid'>
         <Modal  className='admin_color  rounded' isOpen={modal_status}  toggle={toggle} >
        <ModalHeader  className='admin_color text-center w-100 ' toggle={toggle}>
            <h2 className='w-100 text-center fw-bold '> Transaction details <AutoGraphIcon/></h2>
            </ModalHeader>
            <ModalBody className='admin_color p-3'>
              <div className='row'>
                    <div className='col-sm-10 mx-auto p-3 bg-white shadow rounded'>
                    <div className='col-sm-12 mx-auto  p-1'>
                    <div className='d-flex justify-content-center bg-white p-1'>
                        <img src={Member_data.image==null?"/Assets/blank-profile-picture.webp":`https://eeva-server.ethiopianevaluationassociation.org/Profile/${Member_data.image}`} className="img-fluid rounded-circle border border-primary" style={{height:"150px",objectFit:"contain"}} alt=""/>
                    </div>
                </div>
                <div className='col-sm-12'>
                    <div className='d-flex flex-column mt-3 p-2 rounded '>
            
                        <div className='message_modal_container' style={{gap:"10px"}}>
                        <h6 className='text-secondary'>Fullname :</h6>
                        <h6 className='text-dark fw-bold '> {Member_data?.firstname} {Member_data?.lastname}</h6>

                        </div>
                        <div className='message_modal_container' style={{gap:"10px"}}>
                        <h6 className='text-secondary'>Email:</h6>
                        <h6 className='text-dark fw-bold '>{Member_data?.email}</h6>

                        </div>
                        <div className='message_modal_container' style={{gap:"10px"}}>
                        <h6 className='text-secondary'>Phonenumber :</h6>
                        <h6 className='text-dark fw-bold '>{Member_data?.phonenumber}  </h6>

                        </div>

                        <div className='message_modal_container' style={{gap:"10px"}}>
                        <h6 className='text-secondary'>Date:</h6>
                        <h6 className='text-dark fw-bold '>{  moment(Member_data?.date  ).format('YYYY-MM-DD')}</h6>
                        </div>

                        <div className='message_modal_container' style={{gap:"10px"}}>
                        <h6 className='text-secondary'>Transaction Reference:</h6>
                        <h6 className='text-dark fw-bold '>{transaction_data?.gateway_transaction_id}</h6>
                        </div>

                        <div className='message_modal_container' style={{gap:"10px"}}>
                        <h6 className='text-secondary'>Reason:</h6>
                        <h6 className='text-dark fw-bold '>{transaction_data?.description}</h6>
                        </div>

                        <div className='message_modal_container' style={{gap:"10px"}}>
                        <h6 className='text-secondary'>Amount:</h6>
                        <h5 className='badge bg-success text-light fw-bold p-2'>{nf.format(transaction_data?.amount)}</h5>
                        </div>

                        <div className='message_modal_container' style={{gap:"10px"}}>
                        <h6 className='text-secondary'>status:</h6>
                       
                        <h6 className={transaction_data?.status=="Declined" ?"badge bg-danger text-light fw-bold":(transaction_data?.status=="Pending")?"badge bg-primary text-light fw-bold": "badge bg-success fw-bold text-light"}>
                            {transaction_data?.status}</h6>
                        </div>

                    </div>
                </div>
                    </div>
              </div>
                <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
                </ModalBody>
            </Modal>
            </div>
  )
}

export default Transaction_details_modal